import { Drawer, IconButton, List, ListItem } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import {
  Box as Cube,
  Briefcase,
  FilePlus,
  FileText,
  Layout as LayoutIcon,
  Settings
} from 'react-feather'
import { NavLink } from 'react-router-dom'

import { isProd } from '../ApiPath'
import { useDashboardStyles } from '../assets/styles/DashboardStyles'
import { useProjectStore } from '../services/stores/ProjectStore'

export enum MenuKeys {
  Projects = 'Projects',
  Dashboard = 'Dashboard',
  Label = 'Label',
  Train = 'Train',
  Use = 'Use',
  Knowledge = 'Knowledge',
  Chat = 'Chat',
  Settings = 'Settings'
}

interface IMenu {
  title: MenuKeys
  path: string
  icon: JSX.Element
}

const projectsMenu: IMenu[] = [
  {
    title: MenuKeys.Projects,
    path: 'projects',
    icon: <Briefcase size={25} />
  }
]

const dashboardMenu: IMenu[] = [
  {
    title: MenuKeys.Dashboard,
    path: 'dashboard',
    icon: <LayoutIcon size={25} />
  }
]

const trainAndUseMenus: IMenu[] = [
  {
    title: MenuKeys.Label,
    path: 'label',
    icon: <FileText size={25} />
  },
  {
    title: MenuKeys.Train,
    path: 'train',
    icon: <Cube size={25} />
  }
]

const useMenu: IMenu[] = [
  {
    title: MenuKeys.Use,
    path: 'use',
    icon: <FilePlus size={25} />
  }
]

const KnowledgeMenu: IMenu[] = [
  {
    title: MenuKeys.Knowledge,
    path: 'knowledge',
    icon: <FilePlus size={25} />
  }
]

const ChatMenu: IMenu[] = [
  {
    title: MenuKeys.Chat,
    path: 'chat',
    icon: <FilePlus size={25} />
  }
]

const adminMenu: IMenu[] = [
  {
    title: MenuKeys.Settings,
    path: 'settings',
    icon: <Settings size={25} />
  }
]

interface IMainDrawerProps {
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MainDrawer = ({ openMenu, setOpenMenu }: IMainDrawerProps) => {
  const classes = useDashboardStyles()
  const currentProject = useProjectStore((state) => state.currentProject)
  const projectLoading = useProjectStore((state) => state.projectLoading)

  const businessLevelMenuKeys = !currentProject.id
    ? [MenuKeys.Projects, MenuKeys.Chat, MenuKeys.Settings]
    : [MenuKeys.Projects, MenuKeys.Settings]

  const menuItems: IMenu[] = useMemo(() => {
    if (currentProject.isValid() && currentProject.isAiAssistant()) {
      return [
        ...projectsMenu,
        ...dashboardMenu,
        ...KnowledgeMenu,
        ...ChatMenu,
        ...adminMenu
      ]
    }
    if (currentProject.isValid() && !currentProject.isTrainingSupported()) {
      return [
        ...projectsMenu,
        ...dashboardMenu,
        ...useMenu,
        ...ChatMenu,
        ...adminMenu
      ]
    }
    if (currentProject.isValid() && currentProject.isTrainingSupported()) {
      return [
        ...projectsMenu,
        ...dashboardMenu,
        ...trainAndUseMenus,
        ...useMenu,
        ...ChatMenu,
        ...adminMenu
      ]
    }
    return [...projectsMenu, ...ChatMenu, ...adminMenu]
  }, [currentProject])
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={openMenu}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <a
          href={window.location.origin}
          style={{ marginTop: '4px', height: '30px', width: '30px' }}
        >
          <img alt="SmartDocs" src="/static/images/logoSd.svg" />
        </a>
        <IconButton onClick={() => setOpenMenu(false)}>
          <ChevronLeft className={classes.drawerButton} />
        </IconButton>
      </div>
      <List className={classes.menuList} disablePadding>
        {menuItems.map((menuItem) => {
          const link = businessLevelMenuKeys.includes(menuItem.title)
            ? `${menuItem.path}`
            : `project/${currentProject.id}/${menuItem.path}`
          return (
            <ListItem
              id={menuItem.title}
              key={menuItem.title}
              disableGutters
              className={classes.navLinkContainer}
            >
              {!projectLoading ? (
                <NavLink
                  className={({ isActive }) =>
                    clsx(classes.navLink, {
                      [classes.activeNavLink]: isActive
                    })
                  }
                  to={link}
                >
                  {menuItem.icon}
                  <span className={classes.menuItemTextContainer}>
                    {menuItem.title}
                  </span>
                  <span className={classes.ripple}></span>
                </NavLink>
              ) : (
                <div className={clsx(classes.navLink, classes.navLinkDisabled)}>
                  {menuItem.icon}
                  <span className={classes.menuItemTextContainer}>
                    {menuItem.title}
                  </span>
                  <span className={classes.ripple}></span>
                </div>
              )}
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export default MainDrawer
