import _ from 'lodash'

export enum ProjectType {
  BankStatement = 'BankStatement',
  ContractNote = 'ContractNote',
  WaterBill = 'WaterBill',
  Dividend = 'Dividend',
  RentalYearly = 'RentalYearly',
  RentalSummary = 'RentalSummary',
  InsuranceReceipt = 'InsuranceReceipt',
  UnrealisedCapitalGain = 'UnrealisedCapitalGain',
  Invoice = 'Invoice',
  Receipt = 'Receipt',
  BusinessCard = 'BusinessCard',
  IdCard = 'IdCard',
  ChartOfAccounts360 = 'ChartOfAccounts360',
  BusinessModelType = 'BusinessModelType',
  BankAuthority = 'BankAuthority',
  SignatureDetection = 'SignatureDetection',
  FreeForm = 'FreeForm',
  MultiClassClassification = 'MultiClassClassification',
  MultiLabelClassification = 'MultiLabelClassification',
  ObjectDetection = 'ObjectDetection',
  ObjectCounting = 'ObjectCounting',
  RolloverBenefitStatement = 'RolloverBenefitStatement',
  AtoActivityStatement = 'AtoActivityStatement',
  AtoPaygInstalmentReport = 'AtoPaygInstalmentReport',
  AtoQuarterlyPaygInstalmentNotice = 'AtoQuarterlyPaygInstalmentNotice',
  CAS360Form = 'CAS360Form',
  PrivacyRedaction = 'PrivacyRedaction',
  AnnualTaxStatement = 'AnnualTaxStatement',
  ASICFee = 'ASICFee',
  PropertySettlementStatement = 'PropertySettlementStatement',
  CouncilRate = 'CouncilRate',
  UtilityBill = 'UtilityBill',
  InvestmentReport = 'InvestmentReport',
  SplitDocument = 'SplitDocument',
  AiAssistant = 'AiAssistant',
  UnrealisedCGT = 'UnrealisedCGT',
  WrapSummary = 'WrapSummary',
  TrialBalance = 'TrialBalance',
  ATOBasStatement = 'ATOBasStatement',
  IdDocument = 'IdDocument',
  ATOStatementOfAccount = 'ATOStatementOfAccount',
  PaymentDetails = 'PaymentDetails'
}

export const ProjectTypeName: { [key in ProjectType]: string } = {
  BankStatement: 'Bank Statement',
  BusinessCard: 'Business Card',
  ContractNote: 'Contract Note',
  WaterBill: 'Water Bill',
  Dividend: 'Dividend Statement',
  RentalYearly: 'Rental Yearly',
  RentalSummary: 'Rental Summary',
  InsuranceReceipt: 'Insurance Receipt',
  FreeForm: 'Free Form',
  IdCard: 'Id Card',
  Invoice: 'Invoice',
  MultiClassClassification: 'MultiClass-Classification',
  MultiLabelClassification: 'MultiLabel-Classification',
  Receipt: 'Receipt',
  UnrealisedCapitalGain: 'Unrealised CapitalGain',
  ChartOfAccounts360: 'Chart Of Accounts in 360',
  BusinessModelType: 'Business Model Type',
  BankAuthority: 'Bank Authority',
  SignatureDetection: 'Signature Detection',
  RolloverBenefitStatement: 'Rollover Benefit Statement',
  ObjectDetection: 'Object Detection',
  ObjectCounting: 'Object Counting',
  AtoActivityStatement: 'ATO Activity Statement',
  AtoPaygInstalmentReport: 'ATO PAYG Instalment Report',
  AtoQuarterlyPaygInstalmentNotice: 'ATO Quarterly PAYG Instalment Notice',
  CAS360Form: 'CAS360 Forms',
  PrivacyRedaction: 'Privacy Redaction',
  AnnualTaxStatement: 'Annual Tax Statement',
  ASICFee: 'ASIC Fee',
  PropertySettlementStatement: 'Property Settlement Statement',
  CouncilRate: 'Council Rate',
  UtilityBill: 'Utility Bill',
  InvestmentReport: 'Investment Report',
  SplitDocument: 'Split Document',
  AiAssistant: 'AI-Assistant',
  UnrealisedCGT: 'Unrealised CGT',
  WrapSummary: 'Wrap Summary',
  TrialBalance: 'Trial Balance',
  ATOBasStatement: 'ATO BAS Statement',
  IdDocument: 'Id Document',
  ATOStatementOfAccount: 'ATO Statement Of Account',
  PaymentDetails: 'Payment Details'
}

export const ProductionProjectType: Set<ProjectType> = new Set([
  ProjectType.FreeForm,
  ProjectType.BankStatement,
  ProjectType.Invoice,
  ProjectType.MultiClassClassification,
  ProjectType.ObjectCounting,
  ProjectType.ObjectDetection,
  ProjectType.PrivacyRedaction,
  ProjectType.Receipt,
  ProjectType.SignatureDetection,
  ProjectType.AnnualTaxStatement,
  ProjectType.ContractNote,
  ProjectType.Dividend,
  ProjectType.PropertySettlementStatement,
  ProjectType.RentalSummary,
  ProjectType.UtilityBill,
  ProjectType.UnrealisedCGT,
  ProjectType.SplitDocument,
  ProjectType.WrapSummary,
  ProjectType.CouncilRate,
  ProjectType.ASICFee,
  ProjectType.ATOBasStatement,
  ProjectType.IdDocument
])

const dataExtractionProjectType: Set<ProjectType> = new Set([
  ProjectType.FreeForm,
  // ProjectType.AtoActivityStatement,
  ProjectType.AtoPaygInstalmentReport,
  ProjectType.AtoQuarterlyPaygInstalmentNotice,
  ProjectType.BankAuthority,
  ProjectType.BankStatement,
  ProjectType.BusinessCard,
  ProjectType.ContractNote,
  ProjectType.Dividend,
  ProjectType.IdCard,
  ProjectType.InsuranceReceipt,
  ProjectType.Invoice,
  ProjectType.Receipt,
  ProjectType.RentalSummary,
  ProjectType.RentalYearly,
  ProjectType.RolloverBenefitStatement,
  ProjectType.UnrealisedCapitalGain,
  ProjectType.WaterBill,
  ProjectType.AnnualTaxStatement,
  ProjectType.ASICFee,
  ProjectType.PropertySettlementStatement,
  ProjectType.CouncilRate,
  ProjectType.UtilityBill,
  ProjectType.InvestmentReport,
  ProjectType.CAS360Form,
  ProjectType.ChartOfAccounts360,
  ProjectType.BusinessModelType,
  ProjectType.SplitDocument,
  ProjectType.UnrealisedCGT,
  ProjectType.WrapSummary,
  ProjectType.TrialBalance,
  ProjectType.ATOBasStatement,
  ProjectType.IdDocument,
  ProjectType.ATOStatementOfAccount,
  ProjectType.PaymentDetails
])

const dataClassificationProjectType: Set<ProjectType> = new Set([
  ProjectType.MultiClassClassification,
  ProjectType.MultiLabelClassification
])

const objectDetectionProjectType: Set<ProjectType> = new Set([
  ProjectType.ObjectDetection,
  ProjectType.SignatureDetection
])

const objectCountingProjectType: Set<ProjectType> = new Set([
  ProjectType.ObjectCounting
])

const privacyRedactionProjectType: Set<ProjectType> = new Set([
  ProjectType.PrivacyRedaction
])

const aiAssistantProjectType: Set<ProjectType> = new Set([
  ProjectType.FreeForm // TODO: Add AI Assistant project types later
])

const dataExtractionProjectCustomTrainType: ProjectType[] = [
  ProjectType.FreeForm
]

const dataClassificationCustomTrainType: ProjectType[] = [
  ProjectType.MultiClassClassification,
  ProjectType.MultiLabelClassification
]

const objectDetectionCustomTrainType: ProjectType[] = [
  ProjectType.ObjectDetection
]

const objectCountingCustomTrainType: ProjectType[] = [
  ProjectType.ObjectCounting
]

export const allCustomerTrainProjectTypes: ProjectType[] = [
  ...dataExtractionProjectCustomTrainType,
  ...dataClassificationCustomTrainType,
  ...objectDetectionCustomTrainType,
  ...objectCountingCustomTrainType
]

const privacyRedactionCustomTrainType: ProjectType[] = []

const aiAssistantCustomTrainType: ProjectType[] = []

export enum ProjectTypeCategory {
  DataExtraction = 'Data Extraction',
  Classification = 'Classification',
  ObjectDetection = 'Object Detection',
  ObjectCounting = 'Object Counting',
  PrivacyRedaction = 'Privacy Redaction',
  AiAssistant = 'AI Assistant'
}

export const projectTypeCategories: ProjectTypeCategory[] = [
  ProjectTypeCategory.DataExtraction,
  ProjectTypeCategory.Classification,
  ProjectTypeCategory.ObjectDetection,
  ProjectTypeCategory.ObjectCounting,
  ProjectTypeCategory.PrivacyRedaction,
  ProjectTypeCategory.AiAssistant
]

export const isDefaultItemisedProjectType = (projectType: ProjectType) => {
  return (
    projectType === ProjectType.RentalSummary ||
    projectType === ProjectType.BankStatement ||
    projectType === ProjectType.InvestmentReport ||
    projectType === ProjectType.UnrealisedCGT ||
    projectType === ProjectType.PropertySettlementStatement ||
    projectType === ProjectType.ASICFee ||
    projectType === ProjectType.TrialBalance ||
    projectType === ProjectType.ATOStatementOfAccount
  )
}

const getAllProjectType = (category: ProjectTypeCategory): Set<ProjectType> => {
  switch (category) {
    case ProjectTypeCategory.DataExtraction:
      return dataExtractionProjectType
    case ProjectTypeCategory.Classification:
      return dataClassificationProjectType
    case ProjectTypeCategory.ObjectDetection:
      return objectDetectionProjectType
    case ProjectTypeCategory.ObjectCounting:
      return objectCountingProjectType
    case ProjectTypeCategory.PrivacyRedaction:
      return privacyRedactionProjectType
    case ProjectTypeCategory.AiAssistant:
      return aiAssistantProjectType
  }
}

export const getProjectType = (
  category: ProjectTypeCategory,
  isProd: boolean
): ProjectType[] => {
  return isProd
    ? _.intersection(
        Array.from(ProductionProjectType),
        Array.from(getAllProjectType(category))
      )
    : Array.from(getAllProjectType(category))
}

const getAllPretrainedTypes = (
  category: ProjectTypeCategory
): ProjectType[] => {
  switch (category) {
    case ProjectTypeCategory.DataExtraction:
      return (
        _.difference(
          Array.from(dataExtractionProjectType),
          dataExtractionProjectCustomTrainType
        ) ?? []
      )
    case ProjectTypeCategory.Classification:
      return (
        _.difference(
          Array.from(dataClassificationProjectType),
          dataClassificationCustomTrainType
        ) ?? []
      )
    case ProjectTypeCategory.ObjectDetection:
      return (
        _.difference(
          Array.from(objectDetectionProjectType),
          objectDetectionCustomTrainType
        ) ?? []
      )
    case ProjectTypeCategory.ObjectCounting:
      return (
        _.difference(
          Array.from(objectCountingProjectType),
          objectCountingCustomTrainType
        ) ?? []
      )
    case ProjectTypeCategory.PrivacyRedaction:
      return (
        _.difference(
          Array.from(privacyRedactionProjectType),
          privacyRedactionCustomTrainType
        ) ?? []
      )
    case ProjectTypeCategory.AiAssistant:
      return (
        _.difference(
          Array.from(aiAssistantProjectType),
          aiAssistantCustomTrainType
        ) ?? []
      )
  }
}

export const getPretrainedTypes = (
  category: ProjectTypeCategory,
  isProd: boolean
): ProjectType[] => {
  return isProd
    ? _.intersection(
        Array.from(ProductionProjectType),
        getAllPretrainedTypes(category)
      )
    : getAllPretrainedTypes(category)
}

const getAllCustomTrainTypes = (
  category: ProjectTypeCategory
): ProjectType[] => {
  switch (category) {
    case ProjectTypeCategory.DataExtraction:
      return dataExtractionProjectCustomTrainType
    case ProjectTypeCategory.Classification:
      return dataClassificationCustomTrainType
    case ProjectTypeCategory.ObjectDetection:
      return objectDetectionCustomTrainType
    case ProjectTypeCategory.ObjectCounting:
      return objectCountingCustomTrainType
    case ProjectTypeCategory.PrivacyRedaction:
      return privacyRedactionCustomTrainType
    case ProjectTypeCategory.AiAssistant:
      return aiAssistantCustomTrainType
  }
}

export const getCustomTrainTypes = (
  category: ProjectTypeCategory,
  isProd: boolean
): ProjectType[] => {
  return isProd
    ? _.intersection(
        Array.from(ProductionProjectType),
        getAllCustomTrainTypes(category)
      )
    : getAllCustomTrainTypes(category)
}
